import { webLightTheme } from '@fluentui/react-components';

export const AppTheme = {
  ...webLightTheme,
  "fontFamilyBase": 'Rubik, sans-serif',
  fontSizeBase300: '16px',
  lineHeightBase300: '24px',
  fontSizeBase400: '18px',
  lineHeightBase400: '28px',
  "borderRadiusNone": "0",
  "borderRadiusSmall": "4px",
  "borderRadiusMedium": "6px",
  "borderRadiusLarge": "8px",
  "borderRadiusXLarge": "12px",
  "colorNeutralForeground1": "#242424",
  "colorNeutralForeground1Hover": "#242424",
  "colorNeutralForeground1Pressed": "#242424",
  "colorNeutralForeground1Selected": "#242424",
  "colorNeutralForeground2": "#424242",
  "colorNeutralForeground2Hover": "#242424",
  "colorNeutralForeground2Pressed": "#242424",
  "colorNeutralForeground2Selected": "#242424",
  "colorNeutralForeground2BrandHover": "#5658bf",
  "colorNeutralForeground2BrandPressed": "#4d4aaa",
  "colorNeutralForeground2BrandSelected": "#5658bf",
  "colorNeutralForeground3": "#616161",
  "colorNeutralForeground3Hover": "#424242",
  "colorNeutralForeground3Pressed": "#424242",
  "colorNeutralForeground3Selected": "#424242",
  "colorNeutralForeground3BrandHover": "#5658bf",
  "colorNeutralForeground3BrandPressed": "#4d4aaa",
  "colorNeutralForeground3BrandSelected": "#5658bf",
  "colorNeutralForeground4": "#707070",
  "colorNeutralForegroundDisabled": "#bdbdbd",
  "colorNeutralForegroundInvertedDisabled": "rgba(255, 255, 255, 0.4)",
  "colorBrandForegroundLink": "#4d4aaa",
  "colorBrandForegroundLinkHover": "#453e93",
  "colorBrandForegroundLinkPressed": "#332760",
  "colorBrandForegroundLinkSelected": "#4d4aaa",
  "colorNeutralForeground2Link": "#424242",
  "colorNeutralForeground2LinkHover": "#242424",
  "colorNeutralForeground2LinkPressed": "#242424",
  "colorNeutralForeground2LinkSelected": "#242424",
  "colorCompoundBrandForeground1": "#5658bf",
  "colorCompoundBrandForeground1Hover": "#4d4aaa",
  "colorCompoundBrandForeground1Pressed": "#453e93",
  "colorBrandForeground1": "#5658bf",
  "colorBrandForeground2": "#4d4aaa",
  "colorBrandForeground2Hover": "#453e93",
  "colorBrandForeground2Pressed": "#281d46",
  "colorNeutralForeground1Static": "#242424",
  "colorBrandForegroundInverted": "#6c76dd",
  "colorBrandForegroundInvertedHover": "#7a85e8",
  "colorBrandForegroundInvertedPressed": "#6c76dd",
  "colorBrandForegroundOnLight": "#5658bf",
  "colorBrandForegroundOnLightHover": "#4d4aaa",
  "colorBrandForegroundOnLightPressed": "#3c327a",
  "colorBrandForegroundOnLightSelected": "#453e93",
  "colorBrandBackground": "#5658bf",
  "colorBrandBackgroundHover": "#4d4aaa",
  "colorBrandBackgroundPressed": "#332760",
  "colorBrandBackgroundSelected": "#453e93",
  "colorCompoundBrandBackground": "#5658bf",
  "colorCompoundBrandBackgroundHover": "#4d4aaa",
  "colorCompoundBrandBackgroundPressed": "#453e93",
  "colorBrandBackgroundStatic": "#5658bf",
  "colorBrandBackground2": "#cbd5ff",
  "colorBrandBackground2Hover": "#b8c4ff",
  "colorBrandBackground2Pressed": "#97a4f7",
  "colorBrandBackgroundInverted": "#ffffff",
  "colorBrandBackgroundInvertedHover": "#cbd5ff",
  "colorBrandBackgroundInvertedPressed": "#a7b4fc",
  "colorBrandBackgroundInvertedSelected": "#b8c4ff",
  "colorNeutralStrokeAccessible": "#616161",
  "colorNeutralStrokeAccessibleHover": "#575757",
  "colorNeutralStrokeAccessiblePressed": "#4d4d4d",
  "colorNeutralStrokeAccessibleSelected": "#5658bf",
  "colorBrandStroke1": "#5658bf",
  "colorBrandStroke2": "#a7b4fc",
  "colorBrandStroke2Hover": "#8895f0",
  "colorBrandStroke2Pressed": "#5658bf",
  "colorBrandStroke2Contrast": "#a7b4fc",
  "colorCompoundBrandStroke": "#5658bf",
  "colorCompoundBrandStrokeHover": "#4d4aaa",
  "colorCompoundBrandStrokePressed": "#453e93",
};
